<template>
  <div class="main">
    <p class="config-title">收货次数设置</p>
    <div class="give">
      <div class="give-title">
        <div class="title1">体验赠送</div>
        <div class="title2">说明：初次入驻的回收商家的体验次数赠送设置</div>
        <div class="title3" @click="openDialog(5, init_give)">变更记录</div>
      </div>
      <div class="give-num">
        <div>赠送次数</div>
        <div class="num">
          <div v-if="!init_give.show">{{ init_give.ruleValue }}</div>
          <el-input
            v-else
            v-model="init_give.ruleValue"
            autofocus
            @blur="validRule('num', 'init_give')"
            placeholder="请输入赠送次数"
          ></el-input>
          <span> 次</span>
        </div>
        <el-button
          size="small"
          type="primary"
          v-if="!init_give.show"
          @click="edit('init_give')"
          >编辑</el-button
        >
        <el-button
          size="small"
          type="primary"
          v-else
          @click="openDialog(1, init_give)"
          >保存</el-button
        >
        <!-- <div class="btn" v-if="!init_give.show" @click="edit('init_give')">编辑</div>
                <div class="btn" v-else @click="openDialog(1, init_give)">保存</div> -->
      </div>
    </div>
    <div class="transaction">
      <div class="give-title">
        <div class="title1">交易扣费</div>
        <div class="title2">
          说明：按回收商家每笔成交订单（收货成功订单）扣除对应次次数
        </div>
        <div class="title3" @click="openDialog(5, warning_threshold)">
          变更记录
        </div>
      </div>
      <div class="flex">
        <div class="give-num">
          <div>收货时扣除</div>
          <div class="num">
            <div v-if="!receiving_consumption.show">
              {{ receiving_consumption.ruleValue }}
            </div>
            <el-input
              v-else
              v-model="receiving_consumption.ruleValue"
              autofocus
              placeholder="请输入赠送次数"
            ></el-input>
            <span> 次</span>
          </div>
          <!-- <div class="btn" v-if="!receiving_consumption.show" @click="receiving_consumption.show = true">编辑</div>
                    <div class="btn" v-else @click="openDialog(1, receiving_consumption)">保存</div> -->
        </div>
        <div class="give-num">
          <div>预警阈值</div>
          <div class="num">
            <div v-if="!warning_threshold.show">
              {{ warning_threshold.ruleValue }}
            </div>
            <el-input
              v-else
              v-model="warning_threshold.ruleValue"
              autofocus
              @blur="validRule('num', 'warning_threshold')"
              placeholder="请输入预警阈值"
            ></el-input>
            <span> 次</span>
          </div>
          <el-button
            size="small"
            type="primary"
            v-if="!warning_threshold.show"
            @click="edit('warning_threshold')"
            style="margin-right: 10px;"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="primary"
            v-else
            @click="openDialog(1, warning_threshold)"
            style="margin-right: 10px;"
            >保存</el-button
          >
          <!-- <div class="btn" v-if="!warning_threshold.show" @click="edit('warning_threshold')">编辑</div> -->
          <!-- <div class="btn" v-else @click="openDialog(1, warning_threshold)">保存</div> -->
          <div>低于阈值则提醒商户充值</div>
        </div>
      </div>
    </div>
    <div class="recharge">
      <div class="give-title">
        <div class="title1">充值金额</div>
        <div class="title2">
          说明：自定义配置回收商家的充值金额、到账次数，可动态添加或修改
        </div>
        <div class="title3" @click="openDialog(6, warning_threshold)">
          变更记录
        </div>
      </div>
      <div class="give-num">
        <div>自定义金额</div>
        <el-button
          style="margin: 0 10px"
          size="small"
          type="primary"
          @click="addRecharge"
          >+添加</el-button
        >
        <!-- <div class="btn ml-10" @click="addRecharge">+添加</div> -->
        <div class="ff60">点击“添加”按钮，动态设置</div>
      </div>
      <div
        class="give-num"
        v-for="(item, index) in rechargeList"
        :key="item.id"
      >
        <div>充值金额</div>
        <div class="num">
          <div v-if="!item.show">{{ item.price }}</div>
          <el-input
            v-else
            v-model="item.price"
            autofocus
            @blur="validRule('price', 'price', index)"
            placeholder="请输入充值金额"
          ></el-input>
          <span> 元</span>
        </div>
        <div>到账次数</div>
        <div class="num">
          <div v-if="!item.show">{{ item.arrivalTimes }}</div>
          <el-input
            v-else
            v-model="item.arrivalTimes"
            autofocus
            @blur="validRule('num', 'arrivalTimes', index)"
            placeholder="请输入到账次数"
          ></el-input>
          <span> 次</span>
        </div>
        <div>赠送次数</div>
        <div class="num">
          <div v-if="!item.show">{{ item.giftTimes }}</div>
          <el-input
            v-else
            v-model="item.giftTimes"
            autofocus
            @blur="validRule('num', 'giftTimes', index)"
            placeholder="请输入赠送次数"
          ></el-input>
          <span> 次</span>
        </div>
        <!-- <div class="btn">编辑</div> -->
        <el-button
          size="small"
          type="primary"
          v-if="!item.show"
          @click="edit(item, index)"
          >编辑</el-button
        >
        <el-button
          size="small"
          type="primary"
          v-else
          @click="openDialog(2, item, index)"
          >保存</el-button
        >
        <el-button
          size="small"
          type="danger"
          @click="openDialog(3, item, index)"
          >删除</el-button
        >
        <!-- <div class="btn" v-if="!item.show" @click="edit(item, index)">编辑</div> -->
        <!-- <div class="btn" v-else @click="openDialog(2, item, index)">保存</div> -->
        <!-- <div class="btn bg-red" @click="openDialog(3, item, index)">删除</div> -->
      </div>
    </div>
    <div class="recharge">
      <div class="give-title">
        <div class="title1">撮合交易扣款次数换算</div>
      </div>
      <div class="give-num">
        <div>撮合交易扣款1次收货次数</div>
        <div style="margin-left:10px;font-weight: bold;">=</div>
        <div class="num">
          <div v-if="!match_convert_tate.show">
            {{ match_convert_tate.ruleValue }}
          </div>
          <el-input
            v-else
            v-model="match_convert_tate.ruleValue"
            autofocus
            @blur="validRule('', 'match_convert_tate')"
            placeholder="请输入换算金额"
          ></el-input>
          <span>元</span>
        </div>
        <el-button
          size="small"
          type="primary"
          v-if="!match_convert_tate.show"
          @click="edit('match_convert_tate')"
          >编辑</el-button
        >
        <el-button
          size="small"
          type="primary"
          v-else
          @click="openDialog(1, match_convert_tate)"
          >保存</el-button
        >
        <!-- <div class="btn" v-if="!match_convert_tate.show" @click="edit('match_convert_tate')">编辑</div> -->
        <!-- <div class="btn" v-else @click="openDialog(1, match_convert_tate)">保存</div> -->
      </div>
    </div>
    <p class="config-title">支付通道设置</p>
    <div class="recharge">
      <div class="give-title">
        <div class="title1">订单付款通道</div>
      </div>
      <div class="config-table mt-20">
        <el-table
          ref="configTable"
          :data="configTableData1"
          tooltip-effect="dark"
          border
        >
          <el-table-column label="支付场景" align="center">
            <template slot-scope="{ row }">{{
              row.scene || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="支付宝收款门槛" align="center">
            <template slot-scope="{ row }">
              <span v-if="!paymentIsEdit" style="color: #0981FF;">{{row.value}}元</span>
              <el-input v-else size="small" maxlength="4" v-model="row.value" placeholder="请输入内容"  @input="(val)=>row.value = val.replace(/[^\d]/g, '')"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="说明" align="center" min-width="200px">
            <template>收款金额 ≤ 门槛使用微信收款，收款金额 > 门槛时使用支付宝收款</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button :style="paymentIsEdit?'color:#ff8695':''" type="text" @click="eidt(row)">{{paymentIsEdit?'保存':'编辑'}}</el-button>
              <el-button type="text" @click="eidtRecords(row,0)">变更记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="recharge">
      <div class="give-title">
        <div class="title1">订单退款通道</div>
      </div>
      <div class="config-table mt-20">
        <el-table
          ref="configTable"
          :data="configTableData2"
          tooltip-effect="dark"
          border
        >
          <el-table-column label="支付场景" align="center">
            <template slot-scope="{ row }">{{
              row.scene || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="配置方式" align="center">
            <template slot-scope="{ row }">
              <div v-if="!collectionIsEdit">{{row.value==='01'?'方式1':row.value==='02'?'方式2':row.value==='03'?'方式3':'--'}}</div>
              <el-select size="small" v-else v-model="row.value" placeholder="请选择">
                <el-option
                  v-for="item in collectionTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="说明" align="center" min-width="150px">
            <template slot-scope="{ row }">
              <div style="width: 100%;">
                <p style="text-align: left">方式1：≤{{row.paymentPrice}}元使用微信通道，>{{row.paymentPrice}}元使用支付宝通道</p>
                <p style="text-align: left">方式2：不管金额大小，统一使用微信</p>
                <p style="text-align: left">方式3：不管金额大小，统一使用支付宝</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button :style="collectionIsEdit?'color:#ff8695':''" type="text" @click="eidt(row)">{{collectionIsEdit?'保存':'编辑'}}</el-button>
              <el-button type="text" @click="eidtRecords(row,1)">变更记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="recharge">
      <div class="give-title">
        <div class="title1">回收商收货次数购买</div>
      </div>
      <div class="config-table mt-20">
        <el-table
          ref="configTable"
          :data="configTableData3"
          tooltip-effect="dark"
          border
        >
          <el-table-column label="支付场景" align="center">
            <template slot-scope="{ row }">{{
              row.scene || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="配置方式" align="center">
            <template slot-scope="{ row }">
              <div v-if="!payTypeIsEdit">{{row.value==='01'?'微信支付':row.value==='02'?'余额支付':'--'}}</div>
              <el-select size="small" v-else v-model="row.value" placeholder="请选择">
                <el-option
                  v-for="item in payTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="说明" align="center" min-width="150px">
            <template>
              <div style="width: 100%;">
                <p style="text-align: left">微信支付：微信充值通道，退款原路退回微信</p>
                <p style="text-align: left">余额支付：购买收获次数走余额充值</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button :style="payTypeIsEdit?'color:#ff8695':''" type="text" @click="eidt(row)">{{payTypeIsEdit?'保存':'编辑'}}</el-button>
              <el-button type="text" @click="eidtRecords(row,2)">变更记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="recharge">
      <div class="give-title">
        <div class="title1">支付通道配置</div>
      </div>
      <div class="mt-20" style="width: 1000px;">
        <el-table
          :data="configTableData4"
          :span-method="objectSpanMethod"
          border>
          <el-table-column
            prop="scene"
            label="支付产品"
            align="center"
            >
          </el-table-column>
          <el-table-column
            prop="payTypeText"
            align="center"
            label="支付方式">
          </el-table-column>
          <el-table-column
            prop="explain"
            label="支付场景"
            width="420px">
          </el-table-column>
          <el-table-column
            prop="amount3"
            align="center"
            label="支付通道">
            <template slot-scope="{ row }">
              <template v-if="row.operateType === 'ALIPAY_QRCODE_Payment_Passage'">
                <div v-if="!row.isEdit">{{row.value==='alipay'?'支付宝官方':row.value==='yeepay'?'易宝':'--'}}</div>
                <el-select size="small" v-else v-model="row.value" placeholder="请选择">
                  <el-option
                    v-for="item in alipayMethodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                <div v-if="!row.isEdit">{{row.value==='wechat'?'微信官方':row.value==='yeepay'?'易宝':'--'}}</div>
                <el-select size="small" v-else v-model="row.value" placeholder="请选择">
                  <el-option
                    v-for="item in payMethodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button :style="row.isEdit?'color:#ff8695':''" type="text" @click="eidtPay(row)">{{row.isEdit?'保存':'编辑'}}</el-button>
              <el-button type="text" @click="eidtRecords(row)">变更记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--提现自动审核设置-->
    <div style="display: flex; align-items: center;margin-bottom: 20px;">
      <p class="config-title" style="margin-right: 10px;margin-bottom: 0;">提现自动审核设置</p><el-button type="primary" plain size="mini" v-if="isSet" @click="editSet">修改</el-button><el-button type="primary" @click="saveSet" size="mini" v-if="isSave">保存</el-button>
    </div>

    <div class="give-title">
      <div class="title1">说明:针对公司提现到银行卡、微信账户、支付宝时,自动审核.黑名单商户,首次提现微信时不自动审核.</div>
    </div>
    <div style="display: flex; align-items: center;margin-top: 20px;margin-bottom: 20px;">
      <span style="margin-right: 10px;color: #333;">启用自动审核</span>
      <el-switch v-model="value1" :disabled="isdisabled">
      </el-switch>
    </div>
    <el-time-select v-model="timevalue" :disabled="timedisabled" :picker-options="{start: '00:00',step: '00:15',end: '23:45'}" placeholder="选择时间" style="width:220px !important" v-if="value1">
    </el-time-select>
    <el-dialog
      :close-on-click-modal="false"
      :width="type < 4 ? `${500}px` : `${1000}px`"
      :title="title"
      :visible="show"
      @close="close"
      top="50px"
    >
      <div class="img-box">
        <div v-if="type < 4">
          <div class="mt-20 ff60">
            说明:
            {{
              type == 3 ? "删除" : "编辑"
            }}数据需要验证身份，请向管理员索要谷歌动态口令进行操作
          </div>
          <div class="flex command">
            <div style="flex-shrink: 0;">动态口令：</div>
            <el-input
              v-if="type == 1"
              v-model.trim="editData.command"
              placeholder="请输入谷歌动态口令"
            ></el-input>
            <el-input
              v-else
              v-model.trim="rechargeData.command"
              placeholder="请输入谷歌动态口令"
            ></el-input>
          </div>
        </div>
        <div v-else>
          <div v-if="type == 5">
            <el-table :data="list" border style="width: 100%" id="excelOrder">
              <el-table-column type="index" label="序号" align="center" />
              <el-table-column
                prop="ruleValueNew"
                label="变更后数据"
                align="center"
              />
              <el-table-column
                prop="ruleValueOld"
                label="原始数据"
                align="center"
              />
              <el-table-column
                prop="createTime"
                label="变更时间"
                align="center"
              />
              <el-table-column
                prop="adminAcc"
                label="操作账号"
                align="center"
              />
            </el-table>
            <div class="orderPagina">
              <el-pagination
                background
                :current-page="pages.pageNum"
                :page-size="5"
                layout="total, prev, pager, next,jumper"
                :total="total"
                class="el-pagination-c"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
          <div v-if="type == 6">
            <div class="flex tags">
              <div
                class="tag"
                :class="page.type == '02' ? 'choose' : 'defalut'"
                @click="chooseTag('02')"
              >
                修改
              </div>
              <div
                class="tag br"
                :class="page.type == '03' ? 'choose' : 'defalut'"
                @click="chooseTag('03')"
              >
                删除
              </div>
              <div
                class="tag"
                :class="page.type == '01' ? 'choose' : 'defalut'"
                @click="chooseTag('01')"
              >
                新增
              </div>
            </div>
            <el-table :data="list" border style="width: 100%" id="excelOrder">
              <el-table-column type="index" label="序号" align="center" />
              <el-table-column
                v-if="page.type == '03'"
                prop="rechargeValueOld"
                label="删除数据"
                align="center"
              />
              <el-table-column
                v-if="page.type == '03'"
                prop="createTime"
                label="删除时间"
                align="center"
              />
              <el-table-column
                v-if="page.type == '01'"
                prop="rechargeValueNew"
                label="新增数据"
                align="center"
              />
              <el-table-column
                v-if="page.type == '01'"
                prop="createTime"
                label="新增时间"
                align="center"
              />
              <el-table-column
                v-if="page.type == '02'"
                prop="rechargeValueNew"
                label="变更后数据"
                align="center"
              />
              <el-table-column
                v-if="page.type == '02'"
                prop="rechargeValueOld"
                label="原始数据"
                align="center"
              />
              <el-table-column
                v-if="page.type == '02'"
                prop="createTime"
                label="变更时间"
                align="center"
              />
              <el-table-column
                prop="adminAcc"
                label="操作账号"
                align="center"
              />
            </el-table>
            <div class="orderPagina">
              <el-pagination
                background
                :current-page="page.pageNum"
                :page-size="5"
                layout="total, prev, pager, next,jumper"
                :total="totals"
                class="el-pagination-c"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="type == 1 || type == 2 || type == 3"
      >
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submint">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      width="500px"
      title="操作提示"
      :visible.sync="commandDiaShow"
      @close="command = ''"
    >
      <div class="img-box">
        <div class="mt-20 ff60">
            说明:编辑数据需要验证身份，请向管理员索要谷歌动态口令进行操作
        </div>
        <div class="flex command">
          <div style="flex-shrink: 0;">动态口令：</div>
          <el-input
            v-model.trim="command"
            placeholder="请输入谷歌动态口令"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" >
        <el-button @click="commandDiaShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click="commandSubmint">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="变更记录"
      :visible.sync="recordsDiaVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="recordsDiaClose"
    >
      <div style="margin-bottom: 20px;">
        <span>选择变更类型：</span>
        <el-select v-model="configKey" placeholder="请选择" @change="tabAction">
          <el-option
            v-for="item in logOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="recordsLoading"
        :columns="recordsTableColumns"
        :data="recordsTableList"
        :currentPage="recordsPages.pageNum"
        :pageSize="recordsPages.pageSize"
        :total="recordsPages.total"
        @handleCurrentChange="getPaymentConfigChangeLog"
      >
        <el-table-column label="变更人" slot="createAdminAcc" align="center">
          <template slot-scope="{ row }">
            <div>
              {{ row.createAdminName }}-{{ row.createAdminAcc }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="变更前" slot="oldValue" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.configKey === 'Min_Use_Alipay_Money'">
              {{ row.oldValue }}元
            </div>
            <div v-if="row.configKey === 'Order_Refund_Use_Payment_Channel'">
              {{row.oldValue==='01'?'方式1':row.oldValue==='02'?'方式2':row.oldValue==='03'?'方式3':'--'}}
            </div>
            <div v-if="row.configKey === 'Merchant_Purchase_Frequency_Channel'">
              {{row.oldValue==='01'?'微信支付':row.oldValue==='02'?'余额支付':'--'}}
            </div>
            <div v-if="row.configKey === 'WEIXIN_BOWSER_Payment_Passage' || row.configKey === 'WEIXIN_QRCODE_Payment_Passage'">
              {{row.oldValue==='wechat'?'微信官方':row.oldValue==='yeepay'?'易宝':'--'}}
            </div>
            <div v-if="row.configKey === 'ALIPAY_QRCODE_Payment_Passage'">
              {{row.oldValue==='alipay'?'支付宝官方':row.oldValue==='yeepay'?'易宝':'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="变更后" slot="newValue" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.configKey === 'Min_Use_Alipay_Money'">
              {{ row.newValue }}元
            </div>
            <div v-if="row.configKey === 'Order_Refund_Use_Payment_Channel'">
              {{row.newValue==='01'?'方式1':row.newValue==='02'?'方式2':row.newValue==='03'?'方式3':'--'}}
            </div>
            <div v-if="row.configKey === 'Merchant_Purchase_Frequency_Channel'">
              {{row.newValue==='01'?'微信支付':row.newValue==='02'?'余额支付':'--'}}
            </div>
            <div v-if="row.configKey === 'WEIXIN_BOWSER_Payment_Passage' || row.configKey === 'WEIXIN_QRCODE_Payment_Passage'">
              {{row.newValue==='wechat'?'微信官方':row.newValue==='yeepay'?'易宝':'--'}}
            </div>
            <div v-if="row.configKey === 'ALIPAY_QRCODE_Payment_Passage'">
              {{row.newValue==='alipay'?'支付宝官方':row.newValue==='yeepay'?'易宝':'--'}}
            </div>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
      <!-- 操作提示 -->
      <el-dialog
      title="操作提示"
      :visible.sync="quotationShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="command1 = ''"
    >
      <div class="enable-tip">
        <div>说明：编辑数据需要验证身份,请向管理员索要谷歌动态口令进行操作</div>
      </div>
      <div class="command">
        <div style="width: 100px">动态口令：</div>
        <el-input
          v-model="command1"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            quotationShow = false;
            command1 = '';
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="hidePriceButLoading"
          @click.native="quotationSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/utils/request";
import { validateNumber, validateNumberFloat } from "@/utils/validate";
export default {
  data() {
    return {
      // 赠送次数
      init_give: {},
      match_convert_tate: {},
      giveShow: true,
      // 交易扣除
      receiving_consumption: {},
      // 预警阈值
      warning_threshold: {},
      // 变更记录
      pages: {
        pageSize: 5,
        pageNum: 1,
        ruleKey: "",
      },
      total: 0,
      // 套餐变更记录
      page: {
        pageSize: 5,
        pageNum: 1,
        type: "02", // 01:新增02:编辑03:删除
      },
      totals: 0,
      list: [],
      // 套餐初始页面
      rechargeList: [],
      rechargeData: {
        arrivalTimes: "",
        command: "",
        giftTimes: "",
        id: null,
        price: "",
      },
      index: null,
      // 弹窗
      type: null, // 1.编辑体验赠送交易扣费的情况,5.变更记录
      title: "",
      show: false,
      editData: {
        command: "",
        ruleKey: "",
        ruleValue: 0,
      },

      commandDiaShow: false,
      commandBtnLoading: false,
      command: "",
      operateType: "",
      operateValue: "",
      paymentIsEdit: false,
      collectionIsEdit: false,
      payTypeIsEdit: false,
      configTableData1: [{operateType: 'Min_Use_Alipay_Money',scene: "付款码",value: ""}],
      configTableData2: [{operateType: 'Order_Refund_Use_Payment_Channel',scene: "收款码",value: "",paymentPrice:""}],
      configTableData3: [{operateType: 'Merchant_Purchase_Frequency_Channel',scene: "购买收货次数",value: ""}],
      configTableData4: [{
        operateType: 'WEIXIN_BOWSER_Payment_Passage',
        scene: '微信小程序支付',
        payTypeText: '微信',
        explain: '1.【回收端】外部竞价商支付机款；2.【回收端】购买收货次数',
        isEdit: false,
        value: '01'
      }, {
        operateType: 'WEIXIN_QRCODE_Payment_Passage',
        scene: '用户扫码支付',
        payTypeText: '微信',
        explain: '【门店端】申请退款用户扫码支付，生成微信付款码',
        isEdit: false,
        value: '01'
      }, {
        operateType: 'ALIPAY_QRCODE_Payment_Passage',
        scene: '用户扫码支付',
        payTypeText: '支付宝',
        explain: '【门店端】申请退款用户扫码支付，生成支付宝付款码',
        isEdit: false,
        value: '02'
      }],
      paymentConfigChangeLog: [],
      collectionTypeOptions: [
        {
          label: '方式1',
          value: '01',
        },
        {
          label: '方式2',
          value: '02',
        },
        {
          label: '方式3',
          value: '03',
        },
      ],
      payMethodOptions: [
        {
          label: '微信官方',
          value: 'wechat',
        },
        {
          label: '易宝',
          value: 'yeepay',
        },
      ],
      alipayMethodOptions: [
        {
          label: '支付宝官方',
          value: 'alipay',
        },
        {
          label: '易宝',
          value: 'yeepay',
        },
      ],
      payTypeOptions: [
        {
          label: '微信支付',
          value: '01',
        },
        {
          label: '余额支付',
          value: '02',
        }
      ],
      recordsDiaVisible: false,
      recordsLoading: false,
      configKey: "",
      recordsTableList: [],
      recordsPages: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      recordsTableColumns: [
        { label: "变更类型", prop: "configName" },
        { label: "变更时间", prop: "createTime" },
        { slotName: "createAdminAcc" },
        { slotName: "oldValue" },
        { slotName: "newValue" },
      ],
      logOptions: [
        {
          label: "订单付款通道",
          value: 'Min_Use_Alipay_Money',
        },
        {
          label: "订单退款通道",
          value: 'Order_Refund_Use_Payment_Channel',
        },
        {
          label: "收货次数购买方式",
          value: 'Merchant_Purchase_Frequency_Channel',
        },
        {
          label: "微信小程序支付",
          value: 'WEIXIN_BOWSER_Payment_Passage',
        },
        {
          label: "用户扫码支付-微信",
          value: 'WEIXIN_QRCODE_Payment_Passage',
        },
        {
          label: "用户扫码支付-支付宝",
          value: 'ALIPAY_QRCODE_Payment_Passage',
        },
      ],
      isSet:true,//自动审核设置修改按钮
      isSave:false,//自动审核设置保存按钮
      value1:false,//自动审核开关
      isdisabled:true,//自动审核是否禁用
      timevalue:"",//时间
      quotationShow:false,
      command1:"",
      hidePriceButLoading:false,
      timedisabled:true
    };
  },
  created() {
    // 交易扣费,充值金额
    this.getReceivingConfig();
    // 套餐
    this.getRechargeConfigList();
    // 支付配置列表
    this.getPaymentConfig()
    //提现自动审核设置
    this.getAutoPassCompanyTransferConfig()
  },
  watch:{

  },
  methods: {
    // 弹窗操作
    openDialog(type, param, index) {
      this.type = type;
      if (type == 1) {
        this.title = `编辑-${param.ruleName}`;
        this.show = true;
        this.editData = {
          command: "",
          ruleKey: param.ruleKey,
          ruleValue: param.ruleValue,
        };
      }
      if (type == 2 || type == 3) {
        if (type == 3 && !this.rechargeList[index].id) {
          this.rechargeList.splice(index, 1);
        } else {
          this.title = type == 2 ? `编辑数据` : `删除数据`;
          this.index = index;
          this.show = true;
          this.rechargeData = {
            start: param.start,
            arrivalTimes: param.arrivalTimes,
            command: "",
            giftTimes: param.giftTimes,
            id: param.id,
            price: param.price,
          };
        }
      }
      if (type == 5) {
        this.title = `变更记录`;
        this.pages.ruleKey = param.ruleKey;
        this.getReceivingConfigLog();
        this.show = true;
      }
      if (type == 6) {
        this.title = `变更记录`;
        this.getRechargeConfigLog();
        this.show = true;
      }
    },
    close() {
      // 赠送次数和预警阈值时
      if (this.type == 1) {
        if (this.init_give.ruleKey == this.editData.ruleKey) {
          this.init_give.ruleValue = this.init_give.start;
          this.init_give.show = false;
        }
        if (this.warning_threshold.ruleKey == this.editData.ruleKey) {
          this.warning_threshold.ruleValue = this.warning_threshold.start;
          this.warning_threshold.show = false;
        }
      }
      if (this.type == 2) {
        this.rechargeList[this.index].arrivalTimes = this.rechargeList[
          this.index
        ].start.arrivalTimes;
        this.rechargeList[this.index].giftTimes = this.rechargeList[
          this.index
        ].start.giftTimes;
        this.rechargeList[this.index].price = this.rechargeList[
          this.index
        ].start.price;
        this.rechargeList[this.index].show = false;
        this.index = null;
      }
      this.reset();
    },
    reset() {
      this.editData = {
        command: "",
        ruleKey: "",
        ruleValue: "",
      };
      this.pages = {
        pageSize: 5,
        pageNum: 1,
        ruleKey: "",
      };
      this.total = 0;
      this.page = {
        pageSize: 5,
        pageNum: 1,
        type: "02", // 01:新增02:编辑03:删除
      };
      this.totals = 0;
      this.rechargeData = {
        arrivalTimes: 0,
        command: "",
        giftTimes: 0,
        id: null,
        price: 0,
      };
      this.type = null;
      this.index = null;
      this.list = [];
      this.show = false;
    },
    /********************** 体验赠送 交易扣费 ******************/
    // 获取相应初始数据
    getReceivingConfig() {
      _api.getReceivingConfig().then((res) => {
        if (res.code == 1) {
          res.data.map((item) => {
            item.show = false;
            item.btntxt = "编辑";
            item.start = item.ruleValue;
            item.rule = false;
            switch (item.ruleKey) {
              case "INIT_GIVE":
                this.init_give = item;
                break;
              case "RECEIVING_CONSUMPTION":
                this.receiving_consumption = item;
                break;
              case "WARNING_THRESHOLD":
                this.warning_threshold = item;
                break;
              case "MATCH_CONVERT_TATE":
                this.match_convert_tate = item;
                break;
            }
          });
          console.log(this.init_give);
        }
      });
    },
    // 1.失焦编辑置为保存
    edit(name, index) {
      // 重置所有初始状态为置灰
      for (const item of this.rechargeList) {
        item.arrivalTimes = item.start.arrivalTimes;
        item.giftTimes = item.start.giftTimes;
        item.price = item.start.price;
        item.show = false;
      }
      this.warning_threshold.show = false;
      this.warning_threshold.ruleValue = this.warning_threshold.start;
      this.init_give.show = false;
      this.init_give.ruleValue = this.init_give.start;
      // 根据条件转为编写状态
      if ((index && index != undefined) || index == 0) {
        this.rechargeList[index].show = true;
      } else {
        this[name].show = true;
      }
      console.log(this.rechargeList[index]);
    },
    // 2.编辑赠送次数
    submint() {
      let requestParam = [
        {
          url: _api.submintGive,
          data: this.editData,
        },
        {
          url: _api.updateRechargeConfig,
          data: this.rechargeData,
        },
      ];
      if (this.type == 1) {
        if (!this.editData.command) {
          return this.$message.error("请输入动态口令");
        }
        requestParam[0].url(requestParam[0].data).then((res) => {
          this.getReceivingConfig();
          this.reset();
        });
      }
      if (this.type == 2) {
        if (!this.rechargeData.command) {
          return this.$message.error("请输入动态口令");
        }
        if (!requestParam[1].data.id) {
          delete requestParam[1].data.id;
        }
        delete requestParam[1].data.start;
        requestParam[1].url(requestParam[1].data).then((res) => {
          this.getRechargeConfigList();
          this.reset();
        });
      }
      if (this.type == 3) {
        this.removeRechargeConfig();
      }
    },
    // 3.查看记录
    getReceivingConfigLog() {
      _api.getReceivingConfigLog(this.pages).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.list = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    handleCurrentChange(e) {
      console.log(e);
      if (e) {
        if (this.type == 6) {
          this.page.pageNum = e;
          this.getRechargeConfigLog();
        } else {
          this.pages.pageNum = e;
          this.getReceivingConfigLog();
        }
      } else {
        if (this.type == 6) {
          this.pages.pageNum = 1;
          this.getRechargeConfigLog();
        } else {
          this.page.pageNum = 1;
          this.getReceivingConfigLog();
        }
      }
    },
    /********************** 套餐 ******************/
    // 套餐相关记录
    chooseTag(e) {
      this.page = {
        pageSize: 5,
        pageNum: 1,
        type: e,
      };
      this.getRechargeConfigLog();
    },
    getRechargeConfigLog() {
      _api.getRechargeConfigLog(this.page).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.list = res.data.records;
          this.totals = res.data.total;
        }
      });
    },
    // 套餐列表
    getRechargeConfigList() {
      _api.getRechargeConfigList().then((res) => {
        console.log(res);
        if (res.code == 1) {
          for (const item of res.data) {
            item.start = {
              arrivalTimes: item.arrivalTimes,
              giftTimes: item.giftTimes,
              price: item.price,
            };
            item.show = false;
            item.btntxt = "编辑";
          }
          this.rechargeList = res.data;
        }
      });
    },
    // 添加
    addRecharge() {
      for (const item of this.rechargeList) {
        item.show = false;
        item.arrivalTimes = item.start.arrivalTimes;
        item.price = item.start.price;
        item.giftTimes = item.start.giftTimes;
      }
      const item = [
        {
          start: {
            arrivalTimes: 0,
            giftTimes: 0,
            price: 0,
          },
          arrivalTimes: 0,
          giftTimes: 0,
          price: 0,
          show: true,
          btntxt: "新增",
        },
      ];
      this.rechargeList = [...this.rechargeList, ...item];
    },
    // 删除
    removeRechargeConfig() {
      const data = {
        id: this.rechargeData.id,
        command: this.rechargeData.command,
      };
      _api.removeRechargeConfig(data).then((res) => {
        this.getRechargeConfigList();
        this.reset();
      });
    },
    /*********************************验证 */
    validRule(type, name, index) {
      // 次数
      if (type == "num") {
        if ((index && index != undefined) || index == 0) {
          if (!validateNumber(this.rechargeList[index][name])) {
            this.rechargeList[index][name] = this.rechargeList[index].start[
              name
            ];
          }
        } else {
          if (!validateNumber(this[name].ruleValue)) {
            this[name].ruleValue = this[name].start;
          }
        }
      } else {
        console.log(index);
        if ((index && index != undefined) || index == 0) {
          if (!validateNumberFloat(this.rechargeList[index][name])) {
            this.rechargeList[index][name] = this.rechargeList[index].start[
              name
            ];
          }
        } else {
          if (!validateNumberFloat(this[name].ruleValue)) {
            this[name].ruleValue = this[name].start;
          }
        }
      }
    },
    getPaymentConfig() {
      _api.getPaymentConfig().then(res => {
        if (res.code === 1) {
          this.paymentConfigChangeLog = res.data || [];
          this.paymentConfigChangeLog.forEach(item => {
            if(item.key === 'Min_Use_Alipay_Money'){
              this.configTableData1[0].value = item.value
              this.configTableData2[0].paymentPrice = item.value
            }
            if(item.key === 'Order_Refund_Use_Payment_Channel'){
              this.configTableData2[0].value = item.value
            }
            if(item.key === 'Merchant_Purchase_Frequency_Channel'){
              this.configTableData3[0].value = item.value
            }
            if(item.key === 'WEIXIN_BOWSER_Payment_Passage'){
              this.configTableData4[0].value = item.value
            }
            if(item.key === 'WEIXIN_QRCODE_Payment_Passage'){
              this.configTableData4[1].value = item.value
            }
            if(item.key === 'ALIPAY_QRCODE_Payment_Passage'){
              this.configTableData4[2].value = item.value
            }
          })
        }
      });
    },
    eidt(row) {
      this.operateType = row.operateType
      this.operateValue = row.value
      if(row.operateType === 'Min_Use_Alipay_Money'&& !this.paymentIsEdit){
        this.paymentIsEdit = true
        return
      }
      if(row.operateType === 'Order_Refund_Use_Payment_Channel' && !this.collectionIsEdit) {
        this.collectionIsEdit = true
        return
      }
      if(row.operateType === 'Merchant_Purchase_Frequency_Channel' && !this.payTypeIsEdit) {
        this.payTypeIsEdit = true
        return
      }
      this.commandDiaShow = true
    },
    eidtPay(row) {
      this.configTableData4.forEach((item,i) => {
        if(row.operateType === item.operateType){
          if(!item.isEdit){
            item.isEdit = true
          }else{
            this.operateType = row.operateType
            this.operateValue = row.value
            this.commandDiaShow = true
          }
        }
      })
    },
    commandSubmint() {
      if(!this.command){
        return this.$message.error('请输入动态口令')
      }
      let param = {
        command: this.command,
        key: this.operateType || '',
        value: this.operateValue || ''
      }
      this.commandBtnLoading = true
      _api.setPaymentConfig(param).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '修改成功')
          this.getPaymentConfig()
          this.commandDiaShow = false
          if(this.operateType === 'Min_Use_Alipay_Money'){
            this.paymentIsEdit = false
          }else if(this.operateType === 'Order_Refund_Use_Payment_Channel'){
            this.collectionIsEdit = false
          }else if(this.operateType === 'Merchant_Purchase_Frequency_Channel'){
            this.payTypeIsEdit = false
          }
          this.configTableData4.forEach((item,i) => {
            if(this.operateType === item.operateType){
              item.isEdit = false
            }
          })
        }
      }).finally(() => {
        this.commandBtnLoading = false
      });
    },
    eidtRecords(row) {
      this.configKey = row.operateType
      this.recordsDiaVisible = true
      this.getPaymentConfigChangeLog(1)
    },
    recordsDiaClose() {
      
    },
    getPaymentConfigChangeLog(val) {
      if(val){
        this.recordsPages.pageNum = val
      }
      let param = {
        pageNum: this.recordsPages.pageNum,
        pageSize: this.recordsPages.pageSize,
        configKey: this.configKey
      }
      this.recordsLoading = true
      _api.getPaymentConfigChangeLog(param).then(res => {
        if(res.code === 1){
          this.recordsTableList = res.data.records || []
          this.recordsPages.pageNum = res.data.current
          this.recordsPages.total = res.data.total
          this.recordsLoading = false
        }
      });
    },
    tabAction() {
      this.getPaymentConfigChangeLog(1)
    },
    //自动审核设置
    editSet(){
      this.isSet=false,
      this.isSave=true,
      this.isdisabled=false
      this.timedisabled=false
  },
    //保存自动审核设置
    saveSet(){
      if(!this.timevalue&&this.value1){
        return this.$message.error("请选择时间段")
      }
      if(this.value1){
        this.quotationShow=true
      }
      if(!this.value1){
        this.quotationShow=true
        this.timevalue=""
      }
    },
    //最终提交
    quotationSubmit(){
        _api.setAutoPassCompanyTransferConfig({command:this.command1,autoPassTimeRegionStart:this.timevalue,isAutoPassCompanyTransferApply:this.value1}).then(res=>{
          if(res.code==1){
            this.$message.success("操作成功")
            this.isdisabled=true
            this.isSet=true
            this.isSave=false
            this.quotationShow=false
            this.command1=""
            this.timedisabled=true
            this.getAutoPassCompanyTransferConfig()
          }
        })
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 1) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else if (rowIndex === 2) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    //获取自动审核配置
    getAutoPassCompanyTransferConfig(){
      _api.getAutoPassCompanyTransferConfig({}).then(res=>{
        console.log(res,11212)
        this.value1=res.data.isAutoPassCompanyTransferApply
        this.timevalue=res.data.autoPassTimeRegionStart
      })
    }
  },
};
</script>
<style scoped lang="scss">
.main {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  .give,
  .transaction,
  .recharge {
    margin-bottom: 50px;
    padding-left: 20px;
    .give-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      border-left: 3px solid #3399ff;
      padding: 0 10px;
      box-sizing: border-box;
      .title1 {
        font-weight: bold;
        color: #333;
        margin-right: 10px;
      }
      .title2 {
        color: #ff3300;
        margin-right: 10px;
      }
      .title3 {
        color: #3399ff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .give-num {
      display: flex;
      align-items: center;
      margin: 20px 0 0 18px;
      font-size: 14px;
      box-sizing: border-box;
      .num {
        display: flex;
        align-items: center;
        margin: 0 30px 0 10px;
        > div {
          width: 130px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          background: #f2f2f2;
          margin-right: 10px;
          border-radius: 4px;
        }
        > span {
          font-weight: 600;
        }
        /deep/.el-input {
          > input {
            width: 130px;
            height: 28px;
            text-align: center;
          }
        }
      }
      .btn {
        width: 54px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background: #3399ff;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
.flex {
  display: flex;
  align-items: center;
}
.ff60 {
  color: #ff6600;
}
.bg-red {
  background: #ff3300 !important;
}
.ml-10 {
  margin-left: 10px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-20 {
  margin-top: 20px;
}
.command {
  div {
    margin: 20px 0;
    // width: 100px;
  }
  //    /deep/.el-input{
  //         flex: 1;
  //         >input{
  //             width: 100%;
  //             height: 28px;
  //             text-align: left;
  //         }
  //     }
}
.orderPagina {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}
.tags {
  width: 210px;
  border: 1px solid rgba(64, 158, 255, 1);
  box-sizing: border-box;
  margin-bottom: 20px;
  .tag {
    width: 70px;
    height: 28px;
    cursor: pointer;
    text-align: center;
    line-height: 28px;
  }
  .br {
    border-right: 1px solid rgba(64, 158, 255, 1);
  }
  .choose {
    background: rgba(64, 158, 255, 1);
    color: #fff;
  }
  .defalut {
    background: #fff;
    color: rgba(64, 158, 255, 1);
  }
}
.config-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
.config-table{
  width: 1000px;
  /deep/.el-table .el-table__cell{
    padding: 0 !important;
  }
}
.give-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      border-left: 3px solid #3399ff;
      padding: 0 10px;
      box-sizing: border-box;

    }
    .title1 {
        font-weight: bold;
        color: #333;
        margin-right: 10px;
      }
      .title2 {
        color: #ff3300;
        margin-right: 10px;
      }
      .title3 {
        color: #3399ff;
        text-decoration: underline;
        cursor: pointer;
      }
      .command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
}
.enable-tip {
  color: #ff8080;
  font-size: 14px;
  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }

  .set_top_ck {
    span {
      font-size: 15px;
      font-weight: bold;
      margin-right: 10px;
    }
  }
}
</style>
